<template>
  <b-table :items="publications" :fields="filteredFields">
    <template #cell(jobboard)="{item}">
      <div>
        <jobboard-icon class="mr-2" :name="item.product.icon" />
        {{ getJobboardTitle(item) }}
      </div>
    </template>
    <template #cell(description)="{item}">
      <div>
        {{ item.description }}
        <span v-if="item.product.url">
          -
          <a :href="item.product.url[lg]" target="_blank"
            ><translate>Learn more</translate></a
          >
        </span>
      </div>
      <span v-if="item.warning" class="text-danger font-weight-bold">{{
        item.warning
      }}</span>
    </template>
    <template #cell(status)="{item}">
      {{ translateStatus(item.jobboard_offer_status) }}
    </template>
    <template #cell(actions)="{item}">
      <a
        v-if="item.published_urls"
        :href="getOfferPublishedUrl(item)"
        target="_blank"
        class="btn btn-outline-primary btn-icon btn-sm mr-1"
        ><i class="fa fa-eye"></i
      ></a>
      <b-button
        v-if="item.jobboard_offer_status === 'published'"
        @click="$emit('unpublish', item.id)"
        variant="danger"
        class="btn btn-icon btn-sm"
        v-b-tooltip.hover
        :title="$gettext('Remove')"
        ><i class="fa fa-times"></i
      ></b-button>
      <b-button
        :disabled="!item.can_publish"
        v-if="item.jobboard_offer_status !== 'published'"
        @click="$emit('publish', item)"
        variant="success"
        class="btn btn-icon btn-sm"
        v-b-tooltip.hover
        :title="$gettext('Publish')"
        ><i class="fa fa-upload"></i
      ></b-button>
    </template>
  </b-table>
</template>
<script>
import { mapGetters } from "vuex";
import JobboardIcon from "@/components/JobboardIcon";

export default {
  components: { JobboardIcon },
  props: {
    publications: { type: Array, required: true }
  },
  data() {
    return {
      fields: [
        { label: this.$gettext("Jobboard"), key: "jobboard" },
        { label: this.$gettext("Description"), key: "description" },
        { label: this.$gettext("status"), key: "status" },
        { label: this.$gettext("Actions"), key: "actions" }
      ],
      isPosting: false
    };
  },
  computed: {
    ...mapGetters("me", ["canManageOffers", "lg"]),
    filteredFields() {
      return this.fields.filter(field => {
        if (field.key === "actions") {
          return this.canManageOffers;
        }
        return true;
      });
    }
  },
  methods: {
    getOfferPublishedUrl(item) {
      return this.lg in item.published_urls
        ? item.published_urls[this.lg]
        : item.published_urls[this.offer.primary_language];
    },
    translateStatus(status) {
      switch (status) {
        case "published":
          return this.$gettext("status_published");
        case "not_published":
          return this.$gettext("status_not_published");
        case "unpublished":
          return this.$gettext("status_unpublished");
        case "pending":
          return this.$gettext("status_pending");
        default:
          return "";
      }
    },
    getJobboardTitle(item) {
      return item.product.title[this.lg];
    }
  }
};
</script>
