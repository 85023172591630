<template>
  <div>
    <jobboard-icon
      :name="product.icon"
      v-for="(product, index) in products"
      :key="index"
      class="mr-3"
      v-b-tooltip.hover
      :title="product.title[lg]"
    />
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import JobboardIcon from "@/components/JobboardIcon";
import { VBTooltip } from "bootstrap-vue";
Vue.directive("b-tooltip", VBTooltip);

export default {
  components: { JobboardIcon },
  props: {
    products: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters("me", ["lg"])
  }
};
</script>
