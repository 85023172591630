<template>
  <div>
    <b-modal
      @ok="handlePublishOnRecommendedJobboardsOk"
      @cancel="handlePublishOnRecommendedJobboardsCancel"
      id="publish-on-recommended"
      :title="$gettext('Confirmation')"
      :ok-title="$gettext('Yes broadcast my offer')"
      :cancel-title="publishOnRecommendedCancelTitle"
      hide-header-close
      size="lg"
      :ok-disabled="isPosting"
      :cancel-disabled="isPosting"
    >
      <p>{{ publishOnRecommendedMessage }}</p>
      <div
        v-for="jobboard in publishableRecommended"
        :key="jobboard.jobboard_id"
        class="mb-2"
      >
        <div :class="[jobboard.warning ? 'text-muted' : '']">
          <jobboard-icon
            class="mr-2"
            :name="jobboard.product.icon"
            :class="[jobboard.warning ? 'jbsmd-opacity-50' : '']"
          />
          <span>{{ getJobboardTitle(jobboard) }}</span>
          <span v-if="jobboard.warning" class="ml-2"
            ><i
              class="fas fa-exclamation-triangle text-danger"
              v-b-tooltip.hover
              :title="jobboard.warning"
            ></i
          ></span>
        </div>
      </div>
    </b-modal>
    <div class="mb-3 text-right">
      <b-button :disabled="!canBeBumped || isPosting" @click="handleBumpOffer">
        <i class="fa fa-arrow-up mr-2"></i>
        <translate>Bump offer</translate>
      </b-button>
      <b-button
        :disabled="!publishedIds.length || isPosting"
        @click="handleUnpublishOfferFromAll"
        variant="danger"
        class="ml-3"
      >
        <i class="fa fa fa-times mr-2"></i>
        <translate>Unpublish from all</translate>
      </b-button>
    </div>
    <div class="card">
      <div class="card-header">
        <div
          class="card-title d-flex justify-content-between align-items-center"
        >
          <h4 class="m-0">
            <translate>Recommended posting platforms</translate>
          </h4>
          <b-button
            variant="primary"
            class="ml-3"
            :disabled="disablePublishRecommendedButton"
            @click="handlePublishOnAllRecommendedPlatforms"
            ><translate
              >Publish on all recommended posting platforms</translate
            ></b-button
          >
        </div>
        <hr />
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <PublicationTable
              :publications="recommended"
              @publish="handlePublish"
              @unpublish="handleUnpublish"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-if="canPublishOnPartnerOrOurNetwork">
      <div class="card-header">
        <div class="card-title">
          <h4 class="m-0">
            <translate>Other posting platforms</translate>
          </h4>
        </div>
        <hr />
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <PublicationTable
              :publications="others"
              @publish="handlePublish"
              @unpublish="handleUnpublish"
            />
          </div>
        </div>
      </div>
    </div>
    <DiffusionProductCta
      v-if="!canPublishOnPartnerOrOurNetwork && canManageSubscriptions"
      :offer-id="offerId"
      class="col-md-10 offset-xl-1 my-5"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import JobboardIcon from "@/components/JobboardIcon";
import PublicationTable from "@/components/Offers/PublicationTable";
import { msgBoxConfirm } from "@/utils/modal";
import { VBTooltip } from "bootstrap-vue";
import { scrollToTop } from "@/utils/scrollTo";
import DiffusionProductCta from "@/views/Offers/Tabs/DiffusionProductCta.vue";
Vue.directive("b-tooltip", VBTooltip);
export default {
  components: { DiffusionProductCta, JobboardIcon, PublicationTable },
  props: {
    offerId: {
      type: [String, Number],
      require: true
    }
  },
  data() {
    return {
      isPosting: false,
      publishingOnJobboard: {},
      publishOnRecommended: false
    };
  },
  async created() {
    this.startLoader("offer-tab");
    await this.fetchSubscriptions();
    await this.fetchPublications(this.offerId).finally(() =>
      this.stopLoader("offer-tab")
    );
  },
  mounted() {
    scrollToTop();
  },
  computed: {
    ...mapGetters("offers", { offer: "item" }),
    ...mapGetters("jobboards", ["publications", "canBeBumped"]),
    ...mapGetters("subscriptions", ["canPublishOnPartnerOrOurNetwork"]),
    ...mapGetters("me", ["lg", "canManageSubscriptions"]),
    recommended() {
      return this.publications.filter(
        publication =>
          publication.recommended &&
          (this.canPublishOnPartnerOrOurNetwork ||
            this.offer.single_job_posting_active ||
            publication.jobboard_offer_status === "published" ||
            publication.product.name === "career")
      );
    },
    publishableRecommended() {
      return this.recommended.filter(publication => publication.can_publish);
    },
    others() {
      return this.publications.filter(publication => !publication.recommended);
    },
    publishOnRecommendedMessage() {
      const translatedMessage = this.$gettext(
        "Posting a job on %{ jobboardLabel } includes job postings on our Partners Network. Would you like us to post your job everywhere?"
      );
      return this.$gettextInterpolate(translatedMessage, {
        jobboardLabel: this.publishingOnJobboard?.product?.title[this.lg] || ""
      });
    },
    publishOnRecommendedCancelTitle() {
      const translatedCancelTitle = this.$gettext(
        "No thanks, only on %{ jobboardLabel }"
      );
      return this.$gettextInterpolate(translatedCancelTitle, {
        jobboardLabel: this.publishingOnJobboard?.product?.title[this.lg] || ""
      });
    },
    disablePublishRecommendedButton() {
      return this.publishableRecommended.length === 0 || this.isPosting;
    },
    publishedIds() {
      return this.publications
        .filter(
          publication => publication.jobboard_offer_status === "published"
        )
        .map(publication => publication.id);
    },
    jobboardIdsPayload() {
      return this.publishOnRecommended
        ? this.publishableRecommended
            .filter(
              ({ offer_jobboard_status }) =>
                offer_jobboard_status !== "published"
            )
            .map(({ id }) => id)
        : [this.publishingOnJobboard.id];
    }
  },
  methods: {
    ...mapActions("jobboards", [
      "fetchPublications",
      "publishOffer",
      "bumpOffer",
      "unpublishOffer"
    ]),
    ...mapActions({ fetchSubscriptions: "subscriptions/fetch" }),
    ...mapMutations("app", ["startLoader", "stopLoader"]),
    handlePublish(jobboard) {
      this.publishingOnJobboard = jobboard;
      this.publishOnRecommended = false;
      if (this.shouldAskToPublishOnRecommended(jobboard.id)) {
        this.$bvModal.show("publish-on-recommended");
      } else {
        this.publish();
      }
    },
    handleUnpublish(jobboardId) {
      msgBoxConfirm(
        this.$gettext("Are you sure you want to unpublish this offer?")
      ).then(value => {
        if (value.isConfirmed === true) {
          this.isPosting = true;
          this.unpublishOffer({
            offerId: this.offerId,
            jobboards: [jobboardId]
          }).finally(() => (this.isPosting = false));
        }
      });
    },
    publish() {
      this.isPosting = true;
      this.publishOffer({
        jobboards: this.jobboardIdsPayload,
        offerId: this.offerId
      })
        .then(() => {
          this.$bvModal.hide("publish-on-recommended");
        })
        .finally(() => (this.isPosting = false));
    },
    shouldAskToPublishOnRecommended(jobboardId) {
      return (
        this.publishableRecommended.filter(({ id }) => id !== jobboardId)
          .length >= 1
      );
    },
    handlePublishOnRecommendedJobboardsOk(event) {
      event.preventDefault();
      this.publishOnRecommended = true;
      this.publish();
    },
    handlePublishOnRecommendedJobboardsCancel(event) {
      event.preventDefault();
      this.publishOnRecommended = false;
      this.publish();
    },
    handlePublishOnAllRecommendedPlatforms() {
      msgBoxConfirm(
        this.$gettext(
          "Are you sure you want to publish on all recommended platforms?"
        )
      ).then(value => {
        if (value.isConfirmed === true) {
          this.publishOnRecommended = true;
          this.publish();
        }
      });
    },
    handleUnpublishOfferFromAll() {
      msgBoxConfirm(
        this.$gettext(
          "Are you sure you want to unpublish this job from all job boards ?"
        )
      ).then(value => {
        if (value.isConfirmed === true) {
          this.isPosting = true;
          this.unpublishOffer({
            offerId: this.offerId,
            jobboards: this.publishedIds
          }).finally(() => (this.isPosting = false));
        }
      });
    },
    getJobboardTitle(item) {
      return item.product.title[this.lg];
    },
    handleShowPublishOnRecommendedModal(item) {
      this.publishingOnJobboard = item;
      this.$bvModal.show("publish-on-recommended");
    },
    handleBumpOffer() {
      msgBoxConfirm(
        this.$gettext("You can only bump this offer once per day."),
        {
          confirmButtonText: this.$gettext("Bump offer")
        }
      ).then(value => {
        if (value.isConfirmed === true) {
          this.isPosting = true;
          this.bumpOffer(this.offerId)
            .then(() => {
              this.$toast.open({
                message: this.$gettext(
                  "The offer has been successfully bumped!"
                ),
                type: "success"
              });
            })
            .finally(() => {
              this.isPosting = false;
            });
        }
      });
    }
  },
  watch: {
    "$route.params": {
      handler(current, previous) {
        if (parseInt(current.offerId) !== parseInt(previous.offerId)) {
          this.startLoader("offer-tab");
          this.fetchPublications(current.offerId).finally(() =>
            this.stopLoader("offer-tab")
          );
        }
      }
    }
  }
};
</script>
