export const scrollToElement = element => {
  const fixedHeaderHeight = 75;
  const offsetY =
    element.getBoundingClientRect().top +
    document.documentElement.scrollTop -
    fixedHeaderHeight;
  window.scrollTo(0, offsetY);
};

export const scrollToFirstElement = elementsSelector => {
  const elements = document.querySelectorAll(elementsSelector);
  if (elements.length) {
    scrollToElement(elements[0]);
  }
};

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};
