<template>
  <div>
    <JobboardIconList :products="partnerNetworkProducts" />
  </div>
</template>
<script>
import JobboardIconList from "@/components/JobboardIconList";
export default {
  components: { JobboardIconList },
  data() {
    return {
      partnerNetworkProducts: [
        {
          icon: "linkedinJobs",
          title: {
            fr: "LinkedIn",
            en: "LinkedIn"
          }
        },
        {
          icon: "googleJobs",
          title: {
            fr: "Google",
            en: "Google"
          }
        },
        {
          icon: "joobleJobs",
          title: {
            fr: "Jooble",
            en: "Jooble"
          }
        },
        {
          icon: "neuvooJobs",
          title: {
            fr: "Talent.com",
            en: "Talent.com"
          }
        },
        {
          icon: "monsterJobs",
          title: {
            fr: "Monster",
            en: "Monster"
          }
        },
        {
          icon: "job-bank-canada",
          title: {
            fr: "Guichet-Emplois",
            en: "Job Bank Canada"
          }
        }
      ]
    };
  }
};
</script>
