var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{attrs:{"items":_vm.publications,"fields":_vm.filteredFields},scopedSlots:_vm._u([{key:"cell(jobboard)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('jobboard-icon',{staticClass:"mr-2",attrs:{"name":item.product.icon}}),_vm._v(" "+_vm._s(_vm.getJobboardTitle(item))+" ")],1)]}},{key:"cell(description)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.description)+" "),(item.product.url)?_c('span',[_vm._v(" - "),_c('a',{attrs:{"href":item.product.url[_vm.lg],"target":"_blank"}},[_c('translate',[_vm._v("Learn more")])],1)]):_vm._e()]),(item.warning)?_c('span',{staticClass:"text-danger font-weight-bold"},[_vm._v(_vm._s(item.warning))]):_vm._e()]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.translateStatus(item.jobboard_offer_status))+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [(item.published_urls)?_c('a',{staticClass:"btn btn-outline-primary btn-icon btn-sm mr-1",attrs:{"href":_vm.getOfferPublishedUrl(item),"target":"_blank"}},[_c('i',{staticClass:"fa fa-eye"})]):_vm._e(),(item.jobboard_offer_status === 'published')?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-icon btn-sm",attrs:{"variant":"danger","title":_vm.$gettext('Remove')},on:{"click":function($event){return _vm.$emit('unpublish', item.id)}}},[_c('i',{staticClass:"fa fa-times"})]):_vm._e(),(item.jobboard_offer_status !== 'published')?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-icon btn-sm",attrs:{"disabled":!item.can_publish,"variant":"success","title":_vm.$gettext('Publish')},on:{"click":function($event){return _vm.$emit('publish', item)}}},[_c('i',{staticClass:"fa fa-upload"})]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }