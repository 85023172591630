<template>
  <div class="diffusion-product-cta row" v-if="diffusionProduct">
    <div class="bg-primary col-xl-6 d-flex align-items-center">
      <h1 class="text-white mb-0">
        <strong>{{
          $gettext(
            "Equip yourself with our Diffusion package to attract top talents!"
          )
        }}</strong>
        <div>
          {{
            $gettext(
              "Without it, your job postings won’t be visible on our job boards and partners network."
            )
          }}
        </div>
      </h1>
    </div>
    <div class="bg-white col-xl-6 d-flex align-items-center">
      <div>
        <div class="d-flex">
          <div class="product-logo mr-3 mt-1">
            <JobboardIcon :name="diffusionProduct.icon" />
          </div>
          <div>
            <h5 class="my-0">{{ diffusionProduct.title[lg] }}</h5>
            <p class="card-category">
              {{ diffusionProduct.display_pricing[stripeInterval][lg] }}
              <span v-if="stripeInterval === 'year'"
                ><translate> *Billed annually</translate></span
              >
            </p>
          </div>
        </div>
        <p>
          {{ diffusionProduct.description[lg] }}
        </p>
        <div class="text-muted mt-2">
          <u><translate>Partners Network included :</translate></u>
          <PartnerNetworkIncludedIcons class="mt-2" />
        </div>
        <div class="mt-5">
          <b-button
            variant="primary"
            size="lg"
            block
            @click="buyProduct"
            class="px-5 mb-0"
          >
            {{ $gettext("Buy product") }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import JobboardIcon from "@/components/JobboardIcon.vue";
import PartnerNetworkIncludedIcons from "@/components/PartnerNetworkIncludedIcons.vue";

export default {
  components: { PartnerNetworkIncludedIcons, JobboardIcon },
  props: {
    offerId: {
      type: [Number, String],
      required: true
    }
  },
  computed: {
    ...mapGetters({
      lg: "me/lg",
      products: "products/products",
      stripeInterval: "cart/getStripeInterval",
      hasProduct: "cart/hasProduct"
    }),
    diffusionProduct() {
      return this.products.find(product => product.name === "diffusion");
    }
  },
  async created() {
    await this.fetchProducts();
  },
  methods: {
    ...mapActions({
      fetchProducts: "products/fetchAll"
    }),
    ...mapMutations({
      addToCart: "cart/addProduct",
      setRedirect: "cart/setRedirectPath"
    }),
    buyProduct() {
      if (!this.hasProduct(this.diffusionProduct.id)) {
        this.addToCart(this.diffusionProduct);
      }
      this.setRedirect(this.$route.path);
      this.$router.push("/cart");
    }
  }
};
</script>
